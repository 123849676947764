import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { Button, CardContent, Divider } from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"
import Layout from "../components/layout"
import styled from "styled-components"
import { GroupedButtons } from "../components/cart-item"
import CartItem from "../components/cart-item"
import { CSSTransition } from "react-transition-group"
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { TEST_PRODUCT as PRODUCT } from "../stores/cart-store"
import { SecureTransaction } from "../components/sercure-transaction"
import CloseIcon from "@material-ui/icons/Close"
import Box from "@material-ui/core/Box"
import ImageGallery from "react-image-gallery"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { IMAGE_GALLERY } from "../lib/helpers/states"

const PageLayout = styled.div`
  display: grid;
  font-family: Helvetica;
  width: 100%;
  height: auto;
  grid-gap: 16px;
  grid-template-areas:
    "bread-crumbs bread-crumbs bread-crumbs bread-crumbs bread-crumbs"
    "img-container img-container img-container prod-detail prod-detail"
    "img-container img-container img-container prod-detail prod-detail"
    "img-container img-container img-container prod-detail prod-detail";
  margin-top: 95px;
  grid-template-columns: repeat(5, 1fr);

  @media only screen and (max-width: 860px) {
    grid-template-areas:
      "bread-crumbs"
      "img-container"
      "prod-detail";

    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const BreadcrumbsContainer = styled.div`
  grid-area: bread-crumbs;
  a {
    color: #0084ff;
  }
`

const ProductImageContainer = styled.div`
  grid-area: img-container;
  width: 100%;
`

const ImgageStickyContainer = styled.div`
  position: sticky;
  top: 96px;

  img {
    margin-bottom: 0;
  }

  .image-gallery-svg {
    height: 54px;
    width: 33px;
  }

  .image-gallery-thumbnail {
    height: auto;
    margin-bottom: 30px;
    transition: none;
  }

  .image-gallery-thumbnail: hover {
    border: 2px solid #337ab7;
    box-size: border-box;
  }

  .image-gallery-thumbnail.active {
    border: 2px solid #337ab7;
  }
`

const ProductDetailContainer = styled.div`
  grid-area: prod-detail;
  width: 100%;
`

const Title = styled.h1`
  width: 100%;
  color: #848e96;
  margin-bottom: 10px;
  text-transform: uppercase;
  font: 500 18px/1 "Poppins", sans-serif;
`

const Color = styled.div`
  color: #374048;
  text-transform: uppercase;
  font: 700 28px/1 "Poppins", sans-serif;
  margin-bottom: 16px;
`

const PriceCross = styled.del`
  margin-bottom: 16px;
  color: #c;
  font-size: 26px;
  line-height: 30px;
  font-weight: 300;
  margin-right: 14px;
`

const Price = styled.div`
  color: #0084ff;
  font: 600 28px/29px "Poppins", sans-serif;
  margin-bottom: 16px;
`

const FlexContainer = styled.div`
  display: flex;
  text-align: center;
`

const Description = styled.div`
  width: 100%;
  font-size: 16px;
  font: 400 16px/28px "Poppins", sans-serif;
  color: #979a9d;
  a {
    color: #0084ff;
    text-decoration: underline;
  }
`

const QtyContainer = styled.div`
  margin: 16px 0 32px 0px;
`

const ColorOptionContainer = styled.div`
  display: flex;
  margin-top: 16px;
`

const Label = styled.div`
  color: #6c6e71;
  text-transform: uppercase;
  margin: ${props => (props.margin ? props.margin : " 16px 0 16px 0")};
  font: 600 16px/1 "Poppins", sans-serif;
`

const ColorRadio = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${props => (props.color ? props.color : "black")};
`
const Border = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  transition: 0.2s;
  margin-right: 16px;
  border: 4px solid ${props => (props.color ? props.color : "white")};
`

const AddedItemModal = styled.div`
  width: 400px;
  height: auto;
  min-height: 300px;
  top: 80px;
  right: 0;
  background: white;
  position: fixed;
  padding: 16px;
  z-index: 1000;
`

// const Chip = styled.div`
//   background: ${props => (props.selected ? "black" : "white")};
//   color: ${props => (props.selected ? "white" : "black")};
//   border-radius: 100px;
//   padding: 10px 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;
//   font-weight: 700;
//   text-transform: uppercase;
//   cursor: pointer;
// `

const BackDrop = styled.div`
  width: 100%;
  height: 100vh;
  background: black;
  opacity: 0.5;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
`
const useStyles = makeStyles(theme => ({
  button: {
    marginTop: "10px",
  },
  icons: {
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
  },
}))

const LPDProductPage = ({ cart: cartStore }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "front-side.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            src
            srcSet
          }
        }
      }
    }
  `)
  const { cartCount } = cartStore
  const [color, setColor] = useState("black")
  const [model, setModel] = useState("RPA3")
  const [qty, setQty] = useState(1)
  const [addedCartItem, setAddedCartItem] = useState(null)

  let dismissTimer
  const handleAddToCart = (product, options) => {
    dismissTimer = null
    cartStore.addItem(product, options)
    setAddedCartItem({ ...product, ...options })
    dismissTimer = setTimeout(() => {
      setAddedCartItem(null)
    }, 5000)
  }

  const handleCloseModal = () => {
    dismissTimer = null
    setAddedCartItem(null)
  }

  return (
    <Layout>
      <PageLayout>
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Home</Link>
            <div>Product Detail Test Product</div>
            <div>v0</div>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <ProductImageContainer>
          <ImgageStickyContainer>
            <ImageGallery
              items={IMAGE_GALLERY}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              showBullets={true}
              showIndex={true}
              showFullscreenButton={true}
            />
          </ImgageStickyContainer>
        </ProductImageContainer>
        <ProductDetailContainer>
          <CardContent>
            <Title>
              {PRODUCT.name}
              <span>
                <small>
                  {`  `} {PRODUCT.subTitle}
                </small>
              </span>
            </Title>
            <Color>{color === "brown" ? "Bronze" : color}</Color>
            <Rating name="half-rating-read" value={5} precision={0.5} />
            <FlexContainer>
              <PriceCross>$1.00</PriceCross>
              <Price>$0.50</Price>
            </FlexContainer>
            <Box mt="16px" mb="16px">
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Box>
            <Description>
              <Label>Description</Label>

              {PRODUCT.description}
              <span>
                <small>{PRODUCT.subTitle}</small>
              </span>
              <br />
              <a>view cut-sheet</a>
            </Description>
            <Box mt="16px" mb="16px">
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Box>
            <Box mt="16px">
              <small>
                <a href="#specs">Click here </a>to see model specs
              </small>
            </Box>
            <Box mt="16px" mb="16px">
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Box>
            <Label>Choose a color</Label>
            <ColorOptionContainer>
              <Border color={color === "black" ? "black" : null}>
                <ColorRadio
                  onClick={() => setColor("black")}
                  color={color === "black" ? "black" : null}
                />
              </Border>
              <Border color={color === "brown" ? "brown" : null}>
                <ColorRadio onClick={() => setColor("brown")} color="brown" />
              </Border>
            </ColorOptionContainer>
            <Box mt="16px" mb="16px">
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Box>
            <Label>QTY</Label>
            <QtyContainer>
              <GroupedButtons lg getCount={setQty} />
            </QtyContainer>
            <Box mt="16px" mb="16px">
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Box>

            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={() => handleAddToCart(PRODUCT, { qty, color })}
            >
              Add to cart
            </Button>

            <SecureTransaction />

            <Box mt="16px" mb="16px">
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Box>

            <Box mt="8px" mb="8px">
              <small>
                <strong>* Disclaimer </strong>
              </small>
            </Box>
            <small style={{ color: "#979a9d" }}>
              this is a test product for the use of testing.
            </small>
          </CardContent>
        </ProductDetailContainer>
        {!!addedCartItem ? <BackDrop /> : null}
        <CSSTransition
          in={!!addedCartItem}
          timeout={500}
          classNames="alert"
          unmountOnExit
        >
          <AddedItemModal>
            <Box display="flex" justifyContent="space-between">
              <FlexContainer>
                <CheckCircleIcon />
                <Label margin="5px 0 0px 10px">Added to cart</Label>
              </FlexContainer>
              <CloseIcon className={classes.icons} onClick={handleCloseModal} />
            </Box>
            <CartItem item={addedCartItem || PRODUCT} readOnly />
            <Link to="/cart">
              <Button variant="contained" fullWidth color="primary">
                View Cart {cartCount}{" "}
              </Button>
            </Link>
            <Link to="/checkout">
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                className={classes.button}
              >
                CHECKOUT
              </Button>
            </Link>
          </AddedItemModal>
        </CSSTransition>
      </PageLayout>
    </Layout>
  )
}

export default inject("cart")(observer(LPDProductPage))
